import React from 'react';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faShareAlt,
  faQrcode,
} from '@fortawesome/pro-light-svg-icons';

import { useIconOverRide, useLandingHeadline } from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const List = loadable(() => import('@material-ui/core/List'));
const ListItem = loadable(() => import('@material-ui/core/ListItem'));
const ListItemIcon = loadable(() => import('@material-ui/core/ListItemText'));
const ListItemText = loadable(() => import('@material-ui/core/ListItemText'));

const LandingHeadlineIconList = () => {
  const styles = useLandingHeadline();
  const iconOveride = useIconOverRide();

  return (
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} sm={8} md={8} lg={6}>
          <List>
            <ListItem >
              <ListItemIcon classes={iconOveride}>
                <FontAwesomeIcon icon={faAddressCard} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" component="p" className={styles.listItemText}>
                   Create, customize, and brand your loyalty card
                  </Typography>
                } />
            </ListItem>
            <ListItem>
              <ListItemIcon classes={iconOveride}>
                <FontAwesomeIcon icon={faShareAlt} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                <Typography variant="h6" component="p" className={styles.listItemText}>
                  Activate the card and share with customers
                </Typography>
              } />
            </ListItem>
            <ListItem>
              <ListItemIcon classes={iconOveride}>
                <FontAwesomeIcon icon={faQrcode} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                <Typography variant="h6" component="p" className={styles.listItemText}>
                 Scan or search to stamp or redeem
                </Typography>
              } />
            </ListItem>
          </List>
          </Grid>
        </Grid>
  );
};

export default LandingHeadlineIconList;
